import { render, staticRenderFns } from "./PaDeptInit.vue?vue&type=template&id=159e4ed6&scoped=true&"
import script from "./PaDeptInit.vue?vue&type=script&lang=js&"
export * from "./PaDeptInit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159e4ed6",
  null
  
)

export default component.exports