<template>
  <div class='main-page-content'>
    <div>
      <el-form :model='searchCondition' :inline='true' size='mini'>
        <div class='flex-sb'>
          <div>
            <el-form-item>
              <el-date-picker
                v-model='searchCondition.year'
                type='year'
                format='yyyy年'
                value-format='yyyy'
                placeholder='选择年度' @change='loadMcnDeptList' style='width: 140px'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <QuarterSelect size='mini' :quarter.sync='searchCondition.quarter'
                             @change='loadMcnDeptList'></QuarterSelect>
            </el-form-item>
            <el-form-item>
              <DeptSelectTree :searchCondition='searchCondition' ref='deptTree' style='width: 360px'
                              @handleSelect='handleChangeDept'></DeptSelectTree>
            </el-form-item>
            <el-form-item v-if='showMore'>
              <artist-search @handleSelect='handleSelectKol'></artist-search>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='handleTabClick' icon='el-icon-search'>查询</el-button>
              <el-button type='default' @click='reset'>重置</el-button>
            </el-form-item>
            <el-form-item v-if='searchCondition.release_status==="N"'>
              <el-button v-if='hasAuthority("pa_dept_kol_setting")' type='danger' icon='el-icon-setting'
                         @click='showKolSetting'>
                红人管理
              </el-button>
            </el-form-item>
            <el-form-item v-if='searchCondition.release_status==="N"'>
              <el-button icon='el-icon-setting' @click='showSetting' v-if='hasAuthority("pa_dept_kol_setting")'>考核设置
              </el-button>
            </el-form-item>
          </div>
          <div>
            <el-form-item v-if='hasAuthority("pa_dept_init")'>
              <el-button type='warning' icon='el-icon-refresh' v-if='searchCondition.release_status==="N"'
                         @click='showPaDeptInit'>初始化季度绩效目标
              </el-button>
            </el-form-item>
            <el-form-item style='border-radius: 5px;'>
              <el-radio-group size='mini' v-model='searchCondition.release_status' @change='handleChangeRelease'>
                <el-radio-button label='Y'>已发布</el-radio-button>
                <el-radio-button label='N' v-if='userPermissions.indexOf("pa_dept_batch_setting")>-1'>编辑中
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!--    Tab数据-->
    <div>
      <el-tabs v-model='activeName' class='pa-tab' tab-position='left' @tab-click='handleTabClick'>
        <!--        <el-tab-pane label='涨粉绩效目标' name='trend'>-->
        <!--          <PaDeptTrend ref='trend' :edit-mode='editMode' :batch-map.sync='batchMap.trend'-->
        <!--                       @handleSetting='showKolSetting'-->
        <!--                       @query='showQuery' @publishSaved='publishSaved' />-->
        <!--        </el-tab-pane>-->

        <el-tab-pane label='GMV绩效目标' name='gmv'>
          <template slot='label'><span style='text-orientation: upright;'>GMV绩效目标</span></template>
          <PaDeptGmv ref='gmv' :edit-mode='editMode' :batch-map.sync='batchMap.revenue'
                     @handleSetting='showKolSetting'></PaDeptGmv>
        </el-tab-pane>

        <el-tab-pane label='营收绩效目标' name='revenue'>
          <PaDeptRevenue ref='revenue' :edit-mode='editMode' :batch-map.sync='batchMap.revenue'
                         @handleSetting='showKolSetting' />

          <!--          绩效反馈与建议-->
          <div style='margin: 15px auto 20px auto'>
            <pa-suggestion-panel :search-condition='searchCondition'
                                 v-if='userPermissions.indexOf("pa_suggestion_show")>-1' />
          </div>
          <div>
            <!--            计算公式等说明-->
            <RevenueNote />
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>

    <!--    &lt;!&ndash;      批量设置涨粉目标&ndash;&gt;-->
    <!--    <BatchSetPaTrend ref='batchSetPaTrend' type-range='dept' :map='batchMap.trend' />-->
    <!--    &lt;!&ndash;      批量设置营收目标&ndash;&gt;-->
    <!--    <BatchSetPaRevenue ref='batchSetPaRevenue' type-range='dept' :map='batchMap.revenue' />-->
    <!--    红人管理-->
    <PaKolSetting ref='refPaKolSetting'></PaKolSetting>

    <PaDeptInit ref='refPaDeptInit' />

    <CheckSetting :key='`CheckSetting_${activeName}`' ref='checkSetting' @savedSetting='handleTabClick' />
  </div>
</template>

<script>
import QuarterSelect from '@/components/time/QuarterSelect'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PaDeptTrend from '@/pages/pa/dept/trend/PaDeptTrend'
import ExportBtn from '@/components/export/ExportBtn'
import PaDeptRevenue from '@/pages/pa/dept/revenue/PaDeptRevenue'
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import PaKolSetting from '@/pages/pa/dept/PaKolSetting'
import CompanyCascader from '@/components/dept/CompanyCascader'
import McnDeptSelect from '@/pages/pa/kol/McnDeptSelect'

import BatchSetPaRevenue from '@/pages/pa/components/BatchSetPaRevenue'
import DeptCascader from './DeptCascader'
import PaDeptInit from '@/pages/pa/components/PaDeptInit'
import DeptTree from '@/pages/departments/components/DeptTree'
import DeptSelectTree from '@/pages/pa/components/DeptSelectTree'
import CheckSetting from './CheckSetting'
import PaDeptGmv from '@/pages/pa/dept/gmv/PaDeptGmv'
import PaSuggestionPanel from '../components/PaSuggestionPanel'
import RevenueNote from '@/pages/pa/dept/revenue/RevenueNote'

export default {
  name: 'PaDeptIndex',
  components: {
    RevenueNote,
    PaSuggestionPanel,
    PaDeptGmv,
    CheckSetting,
    DeptSelectTree,
    DeptTree,
    PaDeptInit,
    DeptCascader,
    BatchSetPaRevenue,
    McnDeptSelect,
    CompanyCascader,
    PaKolSetting,
    ArtistSearch,
    PaDeptRevenue,
    ExportBtn,
    PaDeptTrend,
    PageHeaderLayout,
    QuarterSelect
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loadingSync: false,
      showMore: false,
      showTip: false,
      showOldVersion: false,
      searchCondition: {
        quarter: null,
        year: null,
        artist_id: null,
        dept_ids: [],//'911572023'
        group_id: null,
        group_name: null,//项目2—5组
        release_status: 'Y'
      },
      options: { groups: [] },
      activeName: 'gmv',
      activeMap: { 'trend': '涨粉', 'revenue': '营收', 'gmv': 'GMV' },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      //编辑模式
      editMode: false,
      batchMap: {
        trend: [],
        revenue: []
      }
    }
  },
  created() {
    this.defaultQuarter()

  },
  mounted() {

    this.handleTabClick()
  },
  methods: {
    showSetting() {
      //设置考核
      if (this.activeMap[this.activeName]) {
        if (this.$refs['checkSetting'])
          this.$refs['checkSetting'].show(this.searchCondition, this.activeName)
      }
    },
    showPaDeptInit() {
      this.$refs['refPaDeptInit'].show(this.searchCondition)
    },
    reset() {
      this.searchCondition = { quarter: null, year: null, artist_id: null, dept_ids: null, release_status: 'Y' }
      this.defaultQuarter()
      // this.handleTabClick()
    },
    handleTabClick() {
      // this.$notify.info(`${JSON.stringify(this.searchCondition)}`)
      this.$nextTick(() => {
        // this.$notify.success(JSON.stringify(this.searchCondition))
        if (this.searchCondition.year && this.searchCondition.quarter) {
          if (this.$refs[this.activeName])
            this.$refs[this.activeName].show(this.searchCondition)
          else {
            this.$notify.warning(`未知的绩效目标`)
          }
        }
      })
    },
    handleChangeRelease() {
      if (this.searchCondition.release_status === 'Y') {
        // this.loadGroupOptions()
        this.editMode = false
      }
      this.handleTabClick()
    },
    handleSelectKol(row) {
      // this.$notify.info(JSON.stringify(row))
      if (row)
        this.searchCondition.artist_id = row.id || null
      else
        this.searchCondition.artist_id = null

      this.handleTabClick()
    },
    async exportExcel() {
      //
      try {
        let fileName = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        if (this.activeName === 'trend') {
          let response = await this.$api.exportPaKolTrendQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else if (this.activeName === 'revenue') {
          let response = await this.$api.exportPaKolRevenueQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else {
          this.$notify.success(this.activeName)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    defaultQuarter() {
      //当前季度
      this.searchCondition.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.searchCondition.quarter = this.$utils.getCurrentQuarter()
      this.loadMcnDeptList()
    },
    loadMcnDeptList() {
      // this.$nextTick(() => {
      //   if (this.$refs['deptSelect'])
      //     this.$refs['deptSelect'].getList()
      // })

      this.changeDeptTree()
      // this.loadGroupOptions()

    },
    showKolSetting() {
      this.$refs['refPaKolSetting'].show(this.searchCondition)
    },

    changeDeptTree() {

      this.$nextTick(() => {
        if (this.$refs['deptTree'])
          this.$refs['deptTree'].show(this.searchCondition)
      })

    },
    async loadGroupOptions() {
      let { list } = await this.$api.loadPaGroupOptions(this.searchCondition)
      this.options.groups = list || []
      // if (list && list.length > 0) {
      //   if (this.searchCondition.group_id) {
      //     // 选择该部门
      //
      //
      //   } else {
      //     this.searchCondition.group_id = list[0].group_id
      //     this.searchCondition.group_name = list[0].group_name
      //     this.searchCondition.dept_ids = [this.searchCondition.group_id]
      //   }
      //
      // }
    },
    handleChangeDept(obj) {
      if (obj.dept_id) {
        this.searchCondition.dept_ids = [obj.dept_id]
        this.searchCondition.group_id = obj.dept_id
        this.searchCondition.group_name = obj.dept_name || ''
        // this.searchCondition.group_name = this.$refs['deptTree'].getNodeName(val)
        this.handleTabClick()
      } else {
        this.searchCondition.dept_ids = []
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
      }
    },

    // eslint-disable-next-line no-unused-vars
    showQuery(query) {
      // console.log('showQuery', query)
    },
    publishSaved() {
      // this.editMode = false
      this.searchCondition.release_status = 'Y'
      this.handleTabClick()
    }
  }


}
</script>
<style>
/**
绩效页面
 */
.pa-tab .el-tabs__item {
  width: 30px;
  line-height: 30px;
  font-size: 18px;
  writing-mode: vertical-lr;
  height: 160px;
  padding-bottom: 20px;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
}

</style>
<style scoped>
.box-tip {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 999;
  border: #e97ca1 1px solid;
  padding: 20px 10px;
  border-radius: 4px;
  width: 80%;
}

.vertical-text {
  writing-mode: vertical-rl; /* 文字从上到下竖排，从右到左 */
  transform: rotate(180deg); /* 修正文字方向，确保从上到下阅读 */
}
</style>
