<template>
  <div>
    <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
               :title='title' width='680px' modal-append-to-body>
      <div>
        <div class='padding-effect'>
          <el-form v-model='formData' ref='refFormData' :rules='rules' :inline='false' label-width='88px'>
            <el-form-item label='' v-if='!showKolList' required>
              <div style='display: flex;flex-direction: row;gap: 5px'>
                <ArtistSearch ref='refArtistSearch' @handleSelect='handleKolSelect' style='width:400px'></ArtistSearch>
                <el-button type='primary' icon='el-icon-plus' @click='submitForm("refFormData")'>添加</el-button>
                <el-button round type='warning' :icon='showKolList?"el-icon-search":"el-icon-s-custom"'
                           @click='handleAddGroupKol()'> {{ showKolList ? '检索红人' : '组内红人' }}
                </el-button>
              </div>
            </el-form-item>

          </el-form>
          <div v-if='showKolList'>
            <h4>《{{ formData.group_name }}》的红人：{{ kolSelection.length }}/{{ kolList.length }}</h4>
            <el-table :data='kolList' max-height='200' @selection-change='handleSelectionKolChange'>
              <el-table-column
                type='selection'
                width='55'>
              </el-table-column>
              <el-table-column label='红人昵称' prop='nickname'></el-table-column>
            </el-table>
            <div style='text-align: center;;padding: 5px;'>
              <el-button type='primary' :disabled='kolSelection.length===0' @click='handleBatchCommit'>
                批量添加({{ kolSelection.length }})
              </el-button>
              <el-button round type='warning' :icon='showKolList?"el-icon-search":"el-icon-s-custom"'
                         @click='handleAddGroupKol()'> {{ showKolList ? '检索红人' : '组内红人' }}
              </el-button>
            </div>
          </div>
        </div>
        <div v-if='formData.year&&formData.quarter&&formData.group_id'>
          <el-button style='font-size: 18px;display: none' type='text' @click='handleQuery' :loading='loading'
                     :disabled='loading'
                     icon='el-icon-refresh'>
            {{ formData.year }}年第{{ formData.quarter }}季度《{{ formData.group_name }}》的绩效考核红人如下：
          </el-button>
          <el-table :data='dataList' highlight-current-row stripe>
            <el-table-column label='序号' type='index'></el-table-column>
            <el-table-column label='红人昵称' prop='nickname' min-width='140' show-overflow-tooltip></el-table-column>
            <el-table-column label='部门/项目组' min-width='200'>
              <template slot-scope='{row}'>
                <span v-if='row.dept_name'>{{ row.dept_name || '-' }} /</span> &nbsp;
                <span> {{ row.group_name || '-' }}</span> &nbsp;
                <el-tag effect='plain'>{{ row.leader_name || '组长未知' }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label='操作' width='80'>
              <template slot-scope='{row}'>
                <el-button type='text' @click='handleRemove(row)' icon='el-icon-close' title='移除数据'></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class='flex-sb'>
            <el-link type='primary' icon='el-icon-refresh' @click='handleQuery'>刷新</el-link>
            <m-pagination @pagination='getList' :total.sync='total' :limit.sync='pageData.page_size'
                          :page.sync='pageData.current_page'></m-pagination>
          </div>

        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import CompanyCascader from '@/components/dept/CompanyCascader'
import UserSearch from '@/pages/user/components/UserSearch'
import MemberSelect from '@/pages/pa/components/MemberSelect'

export default {
  name: 'PaKolSetting',
  components: { MemberSelect, UserSearch, CompanyCascader, ArtistSearch },
  data() {
    return {
      dialogVisible: false,
      showComplete: false,
      title: '红人管理',
      loading: false,
      loadingInit: false,
      dataList: [
        // { artist_id: 1, nickname: 'xxx红人' },
        // { artist_id: 2, nickname: 'xxx红人2' }
      ],
      total: 0,
      pageData: { current_page: 1, page_size: 50 },
      formData: {
        year: null,
        quarter: null,
        group_id: null,
        group_name: null,
        artist_id: null,
        nickname: null,
        leader_name: null,
        leader_id: ''
      },
      checkFields: {
        year: null,
        quarter: null,
        group_id: null,
        group_name: null,
        artist_id: null,
        nickname: null
        // leader_name: null,
        // leader_id: ''
      },
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      //组员
      // groupMembers: [],
      showGroupMembers: false,
      showOtherMembers: false,
      //组内红人列表
      kolList: [],
      showKolList: false,
      kolSelection: [],
      currentRow: {},
      showForm: false,
      rules: {
        year: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ], quarter: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ], group_id: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ], group_name: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ], artist_id: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ], nickname: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ],
        leader_name: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ],
        leader_id: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ]

      },
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ]
    }
  },
  methods: {
    show(query) {
      this.dialogVisible = true
      this.showComplete = false
      this.loadingInit = false
      this.showKolList = false
      this.showGroupMembers = true
      this.showOtherMembers = false
      this.kolSelection = []
      this.kolList = []
      if (query.group_id) {
        this.showForm = false
        this.formData.year = query.year
        this.formData.quarter = query.quarter
        this.formData.group_name = query.group_name
        this.formData.group_id = query.group_id
        this.title = `${this.formData.year}年第${this.formData.quarter}季度【${query.group_name}】红人管理`
        //查询组内红人
        if (this.formData.group_id) {
          // console.log('组',this.formData.group_id)
          setTimeout(() => {
            this.$nextTick(() => {
              this.handleQuery()
            })
          }, 500)

        }
      } else {
        this.title = '红人管理'
        this.showForm = true
      }
    },
    handleQuery() {
      this.getList()
    },
    async getList() {
      this.loading = true
      this.dataList = []
      if (this.formData.group_id && this.formData.year && this.formData.quarter) {
        let params = {
          group_id: this.formData.group_id,
          year: this.formData.year,
          quarter: this.formData.quarter
        }
        Object.assign(params, this.pageData)
        let { list, pages } = await this.$api.getPaGroupSettingList(params)
        this.loading = false
        this.dataList = list || []
        this.total = pages.total || 0
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 1
      }
    },
    handleKolSelect(obj) {
      if (obj) {
        this.currentRow = { artist_id: obj.id, nickname: obj.nickname }
        this.formData.artist_id = this.currentRow.artist_id
        this.formData.nickname = this.currentRow.nickname
      }
    },

    selectUser(user) {
      this.formData.leader_id = user.id
      this.formData.leader_name = user.nickname
    },
    defaultQuarter() {
      //当前季度
      this.formData.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.formData.quarter = this.$utils.getCurrentQuarter()
    },

    async handleAddGroupKol() {
      this.showKolList = !this.showKolList
      //group_kol_list
      this.kolList = []
      if (this.showKolList) {
        let { list } = await this.$api.getGroupKolList(this.formData.group_id)
        this.kolList = list || []
      }
    },
    handleSelectionKolChange(val) {
      this.kolSelection = val
    },
    // eslint-disable-next-line no-unused-vars
    async submitForm(formName) {
      if (this.formData.artist_id) {
        let data = await this.handleSave(this.formData)
        // console.log(data)
        this.$notify.success('添加成功')
        if (data.info) {
          let id = data.info
          if (id)
            await this.handleQuery()
        }
        // 清空筛选红人
        if (this.$refs['refArtistSearch'])
          this.$refs['refArtistSearch'].clearSelect()
      } else {
        this.$notify.warning('红人必填')
      }
    },
    //批量添加
    handleBatchCommit() {
      if (!this.formData.year) {
        this.$notify.warning('年份必选')
        return
      }
      if (!this.formData.quarter) {
        this.$notify.warning('季度必选')
        return
      }
      if (this.kolSelection.length === 0) {
        this.$notify.warning('至少勾选一个红人')
        return
      }
      //批量添加
      let msg = `确定添加？ 红人：${this.kolSelection.length}个`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        const promises = this.kolSelection.map(item => {
          return this.handleSave({
            year: this.formData.year,
            quarter: this.formData.quarter,
            group_id: this.formData.group_id,
            group_name: this.formData.group_name,
            artist_id: item.id,
            nickname: item.nickname,
            leader_name: this.formData.leader_name,
            leader_id: this.formData.leader_id
          })
        })
        await Promise.all(promises)
        this.$message.success('操作成功！')
        //重新加载考核红人列表
        this.handleQuery()
        this.showKolList = false
      })
    },
    async handleSave(formData) {
      if (formData.artist_id) {
        if (this.dataList && this.dataList.findIndex(value => value.artist_id === formData.artist_id) === -1) {
          this.dataList.push({ ...formData })
        } else {
          this.$notify.warning(`红人『${formData.nickname}』已在考核列表`)
          return
        }

        //必要字段校验
        let errMsg = []
        Object.keys(this.checkFields).forEach((field) => {
          if (!formData[field]) {
            errMsg.push(`${field}字段必填`)
          }
        })
        if (errMsg && errMsg.length > 0) {
          this.$notify.warning(errMsg.join('、'))
          return false
        }
        //保存数据
        return await this.$api.savePaGroupSetting(formData)
      }
    },
    handleRemove(row) {
      this.$confirm('确认【移除】考核红人，将同时【删除】已录入的数据（GMV绩效目标、营收绩效目标（包括总监绩效红人绩效目标）），是否确认？', '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.handleDelete(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })


    },

    handleDelete(row) {
      let posIndex = this.dataList.indexOf(row)
      this.dataList.splice(posIndex, 1)
      if (row.id)
        this.$api.deletePaGroupSetting({ id: row.id })
    },
    async handleInit(row, type) {
      this.loadingInit = true
      setTimeout(() => {
        this.loadingInit = false
      }, 2000)
      //初始化
      if (type === 'trend') {
        let { trend_ids } = await this.$api.initPaGroupTrendDeptData({ id: row.id })
        this.$notify.success(`涨粉初始化完毕，同步绩效目标${trend_ids.length}条`)
      }
      if (type === 'revenue') {
        let { revenue_ids } = await this.$api.initPaDeptRevenueDeptData({ id: row.id })
        this.$notify.success(`营收初始化完毕，同步绩效目标${revenue_ids.length}条`)
      }


      this.loadingInit = false
      await this.handleQuery()
    }
  }
}
</script>

<style scoped>

</style>
