<template>
  <div style='margin:10px;display: flex;flex-direction: row;justify-content: center;align-items: center;gap: 20px'>
    <div style='line-height: 20px;text-align: center;vertical-align: middle;'>
      <el-tooltip content='核算完成则锁定该月份的完成值和团队奖金' placement='bottom' effect='light'>
        <i class='el-icon-question theme-color' style='font-size: 1em'></i>
      </el-tooltip>
      <span class='title-check' :title='`若要修改核算完成数据，需要【权限-核算完成】`'>核算完成：</span>
      <el-checkbox-group v-model='checkMonthList' style='display: inline!important;'
                         v-if='quarterlyMonths&&quarterlyMonths.length>0'>
        <el-checkbox :label='month' :name='`month_${month}`' v-for='(month,index) in quarterlyMonths'
                     :key='index'
                     :disabled="userPermissions.indexOf('pa_dept_checked')===-1"
                     @change='changeCheckMonth(month)'>{{
            month
          }}月份
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckRevenuePanel',
  props: {
    showBtnPublish: {
      type: Boolean,
      default() {
        return false
      }
    },
    performanceType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    }
  },
  data() {
    return {
      commitLoading: false,
      searchCondition: {},
      checkMonthList: []
    }
  },
  methods: {
    show(searchCondition, checked_months) {
      this.searchCondition = searchCondition
      this.checkMonthList = checked_months
    },
    //核算操作：
    async changeCheckMonth(month) {

      let params = {
        type: this.performanceType,
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
        this.$emit('changed')
      }
    }
  }
}
</script>

<style scoped>
.title-check {
  font-size: 1.1em;
  font-weight: bold;
}
</style>
