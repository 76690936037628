<template>
  <div>
    <div style='display: flex;flex-direction: row;gap: 10px;justify-content: space-between'>
      <div style='line-height: 20px;margin-bottom: 4px;'>
        <span style='color: #ff3176'>涨粉绩效目标</span>
        <span style='color: #1a1a1a;'>与完成情况</span>
        <span class='group-name'>【{{ searchCondition.group_name || '' }}】</span>

        <el-button v-if='searchCondition.release_status==="N"&&hasAuthority("pa_dept_batch_setting")' size='mini' :type='editMode?"primary":""' @click='changeEditMode'>
          <span :class='editMode?"el-icon-check":"el-icon-edit-outline"'></span>
          {{ editMode ? '【浏览】' : '【编辑】' }} {{ activeMap[activeName] || '-' }}目标
        </el-button>
        <el-button size='mini' @click='showBatch' icon='el-icon-setting' v-if='searchCondition.release_status==="N"&&hasAuthority("pa_dept_batch_setting")'>
          批量设置{{ activeMap[activeName] || '-' }}目标
        </el-button>
        <el-button v-if='searchCondition.release_status==="N" && userPermissions.indexOf("pa_dept_publish")>-1'
                   type='primary' size='mini' @click='saveConfirm' :disabled='commitLoading' :loading='commitLoading'>
          确认发布
        </el-button>
      </div>
      <div style='line-height: 20px;margin-bottom: 8px;'>
        <div style='display: inline-block;margin-bottom: 4px'>
          <LogPanel ref='refLogPanel' module-name='pa_group_trend' v-if='hasAuthority("pa_dept_log")'></LogPanel>
        </div>

        <export-btn ref='export' @export='exportExcel' :can-export='hasAuthority("pa_dept_export")'
                    :lint-title='`${activeMap[activeName]||"-"}绩效导出`'
                    style='margin-left: 5px'>导出
        </export-btn>
        &nbsp;
        &nbsp;
        <!--        <el-button v-if='hasAuthority("pa_dept_kol_setting")' type='danger' icon='el-icon-setting'-->
        <!--                   @click='showKolSetting'>-->
        <!--          红人管理-->
        <!--        </el-button>-->
        <!--        <el-link type='primary' icon='el-icon-refresh' @click='getList'>刷新</el-link>-->
      </div>
    </div>
    <div class='default-table'>
      <div v-loading='loadingStatus'
           :element-loading-text='loadingText'
           element-loading-spinner='el-icon-loading'>
        <el-table
                  :data='tblData'
                  :span-method='objectSpanMethod'
                  @row-contextmenu='rowContextmenu'
                  :row-class-name='rowClassName'
                  @header-contextmenu='headerContextmenu'
                  :cell-class-name='cellClassName'
                  style='width: 100%'
                  border>
          <el-table-column
            prop='nickname'
            label='红人昵称'
            align='center'
            min-width='100'>
            <template slot-scope='{row}'>
              <artist-nickname :info='row' />
              <!--              <div> 行号：{{ $index }}</div>-->
            </template>
          </el-table-column>
          <el-table-column
            prop='platform_name'
            label='平台'
            align='center'
            min-width='80'>
            <template slot-scope='{row}'>
              <span>{{ row.platform_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop='average_value'
            label='上季月涨粉均值'
            min-width='100' header-align='center' align='center'>
            <template slot-scope='scope'>
              <span v-if='scope.$index<tblLen-1' title='上级月涨粉均值'>{{
                  valFormat(scope.row[`average_value`])
                }}</span>
              <span v-else>{{ (scope.row[`average_value`]) }}</span>
            </template>
          </el-table-column>
          <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                           align='center'>
            <el-table-column
              :prop='`quotient_${month}`'
              label='系数'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if='editMode&& !($index===tblLen-2)'
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[calcColProp(column.property, $index, month)]'
                @change='editVal(row,calcColProp(column.property, $index, month),$index)'
                :is-input-number='false'>
                          <span slot='content'>
                            <span  v-if='$index===tblLen-1'
                                   :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                             <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{$index}' v-else-if='$index===tblLen-2'>/</template>
              <template slot-scope='{row,column,$index}' v-else>
                <span  v-if='$index===tblLen-1'
                       :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                  {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                </span>
                <span v-else>
                  {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`flag_value_${month}`'
              label='目标'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if='editMode && !($index===tblLen-2)'
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[calcColProp(column.property, $index, month)]'
                @change='editVal(row,calcColProp(column.property, $index, month),$index)'
                :is-input-number='true'>
                          <span slot='content'>
                            <span  v-if='$index===tblLen-1'
                                   :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                             <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <!--              <template slot-scope='{$index}' v-else-if='$index===tblLen-2'>/</template>-->
              <template slot-scope='{row,column,$index}' v-else>
                <span  v-if='$index===tblLen-1'
                       :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                  {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                </span>
                <span v-else>
                  {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`real_value_${month}`'
              label='完成'
              min-width='100' header-align='center' align='center'>
              <editable-cell
                v-if='editMode&& ($index===tblLen-1)'
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[calcColProp(column.property, $index, month)]'
                @change='editVal(row,calcColProp(column.property, $index, month),$index)'
                :is-input-number='true'>
                          <span slot='content'>
                           <span  v-if='$index===tblLen-1'
                                  :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                             <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row,column,$index}' v-else>
                <span v-if='$index===tblLen-1'
                       :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                  {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                </span>
                <span v-else>
                  {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                </span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <!--      核算和确认发布-->
      <CheckTrendPanel :performance-type='activeName' ref='refCheckPanel' @changed='changedCheck'></CheckTrendPanel>
    </div>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import { numberFormat } from '@/utils/utils'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import CheckTrendPanel from '@/pages/pa/dept/CheckTrendPanel'
import LogPanel from '@/components/logs/LogPanel'

export default {
  name: 'PaDeptTrend',
  components: {
    LogPanel,
    CheckTrendPanel,
    ExportBtn,
    EditableCell
  },
  // props: {
  //   editMode: {
  //     type: Boolean,
  //     default() {
  //       return false
  //     }
  //   }
  // },
  data() {
    return {
      searchCondition: {
        release_status: 'Y',
        year: null,
        quarter: 0,
        dept_ids: [],
        group_id: null,
        group_name: null
      },
      activeName: 'trend',
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      monthsRange: [],//根据季度和年份计算所得
      tipList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1)单个设置，右键“红人昵称”，可进行配置；2)整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）'
      ],
      showIndex: false,//显示序号
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      showEditIcon: true,//编辑图标
      loadingStatus: false,
      commitLoading: false,
      editMode: false,
      condition: {},
      edit_mode: 'add',
      editStatus: false,
      bonus_items: [],//团队奖金
      dataList: [],//绩效目标数据
      mergeFields: ['nickname'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      calcRow: { nickname: '本组总涨粉', platform_name: '本组总涨粉' },
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],//本组总涨粉行的计算字段
      bonusRow: { nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金' },//团队奖金行的可编辑列
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false
      },
      performanceSetting: {
        visible: false
      },
      checkMonthList: []//已核算的月份
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    showBonusView(){
      return this.userPermissions.indexOf('pa_dept_bonus') > -1
    },
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】第${this.searchCondition.quarter}季度的KOL涨粉绩效目标数据加载中...`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter

      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },

    formQuotientTitle() {
      return `批量修改【${this.formQuotient.year}年${this.formQuotient.month}月】【${this.searchCondition.group_name}】涨粉绩效系数`
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()
        //合计数据-初始化-追加一行 本组总涨粉
        let appendRows = [{ nickname: '-', platform_name: '本组总涨粉', ...calcRow }]
        // 追加团队奖金
        let bonusRow = this.bonusRow
        if (this.bonus_items.length > 0) {
          let row = {}
          this.bonus_items.forEach((item) => {
            row[`amount_${item.month}`] = item.amount
          })
          bonusRow = { nickname: '-', platform_name: '-', average_value: '团队奖金', ...row }
        }
        appendRows.push({ ...bonusRow })

        return [...this.dataList, ...appendRows]
      }

      return []
    },
    tblLen() {
      return this.tblData.length
    },
    //合并单元格的行
    mergeRowIndex() {
      if (this.dataList) {
        let arr = [this.dataList.length, this.dataList.length + 1]
        return arr
      } else {
        return []
      }
    }
    // condition() {
    //   return {
    //     release_status: 'N',
    //     year: this.searchCondition.year,
    //     quarter: this.searchCondition.quarter,
    //     group_id: this.searchCondition.group_id,
    //     group_name: this.searchCondition.group_name,
    //     months: this.searchCondition.months,
    //   }
    // }
  },
  methods: {
    show(query) {
      this.searchCondition = query
      this.getList()
    },
    calcColProp(prop, $index, month) {
      //  moneyFormat(row[`${$index === tblLen - 1 ? `amount_${month}` : `real_value_${month}`}`])
      if (this.tblData) {
        if ($index === this.tblLen - 1) {
          //最后一行:团队奖金
          return `amount_${month}`
        } else if ($index === this.tblLen - 1) {
          //倒数第2行:本组的总涨粉
          return prop
        }
      }
      return prop
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    // eslint-disable-next-line
    colFormatter(row, column, cellValue, index) {
      row[column.property] = '平台：' + row[column.property]
    },
    // eslint-disable-next-line
    mouseEnter(row, column, cell, event) {
      console.log(`当单元格 hover 进入时会触发该事件:第${row.$index},列${column.property} }`)
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: { type: 'trend', amount: row[colName], month: month }
      })
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      //团队奖金数据同步，重新计算表格数据的时候要使用
      if (colName.indexOf('amount_') > -1) {
        this.bonusRow[colName] = row[colName]
        this.saveBonus(row, colName)
        return
      }
      if (this.mergeRowIndex.indexOf(rowIndex) > -1) {
        return
      }
      if (!row.artist_id) {
        return
      }

      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      //'year', 'quarter', 'month', 'artist_id', 'platform_code', 'quotient', 'flag_value'
      let saveData = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        artist_id: row.artist_id,
        nickname: row.nickname,
        dept_id: row.dept_id,
        dept_name: row.dept_name,
        // group_id: row.group_id,
        // group_name: row.group_name,
        platform_code: row.platform_code,
        platform_name: row.platform_name
      }
      // console.log(saveData)
      const val = row[colName]
      if (colName.indexOf('quotient_') > -1) {
        let arr = colName.split('quotient_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          // let syncField = `quotient_${saveData['month']}`
          if (val !== '/') {
            if (!isNaN(val)) {
              saveData['quotient'] = val
              saveData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`flag_value_${month}`] = saveData['flag_value']
              this.saveCell(saveData)
            }
          } else {
            saveData['quotient'] = -1
            this.saveCell(saveData)
          }
        }
      } else if (colName.indexOf('flag_value_') > -1) {
        let arr = colName.split('flag_value_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          saveData['flag_value'] = val
          this.saveCell(saveData)
        }

      }

    },
    async initQuarterData(searchCondition) {
      await this.$api.initPaKolTrendQuarterData(searchCondition)
      await this.getList()
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          condition[field] = this.searchCondition[field]
      })

      return condition
    },
    async getList() {

      //重置数据
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.year && searchCondition.quarter) {
        //计算的列
        this.getCalcFields()
      }
      if (!(searchCondition.year && searchCondition.quarter && searchCondition.dept_ids && searchCondition.dept_ids.length > 0)) {
        return
      }
      this.loadingStatus = true
      let { list, bonus_items, checked_months } = await this.$api.getPaDeptTrendDeptData(searchCondition)
      this.loadingStatus = false
      this.bonus_items = bonus_items
      this.checkMonthList = checked_months || []
      this.dataList = list || []
      this.forTblData() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      this.$nextTick(() => {
        this.$refs['refCheckPanel'].show(this.searchCondition, checked_months)
        this.$emit('query', this.searchCondition)
      })
    },
    // ============= 合并单元格 =================
    rowspan: function(index) {
      const fields = this.mergeFields //合并的字段
      fields.forEach((field) => {
        if (index === 0) {
          this.spanObj[field] = []
          this.spanObj[field].push(1)
          this.positionObj[field] = 0
        } else {
          // 合并数据：若和上一行的数据相同，则该字段合并行数加1
          if (this.tblData[index][field] === this.tblData[index - 1][field]) {
            this.spanObj[field][this.positionObj[field]] += 1
            this.spanObj[field].push(0)
          } else {
            this.spanObj[field].push(1)
            this.positionObj[field] = index
          }
        }
      })
    },
    forTblData: function() {
      const batch_set_map = {}
      this.tblData.forEach((item, index) => {
        // 合并单元格
        this.rowspan(index)

        // 生成批量设置目标配置
        if (item.platform_code) {
          const key_batch_set_map = `${item.platform_code}`
          if (batch_set_map[key_batch_set_map] === undefined) {
            batch_set_map[key_batch_set_map] = {
              platform_name: item.platform_name,
              platform_code: item.platform_code,
              val: null
            }
          }
        }
      })
      this.$emit('update:batch-map', Object.values(batch_set_map))
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.tblData && this.tblLen > 2) {
        //末尾查询一行合计：团队奖金
        if (rowIndex === this.tblLen - 1) {
          // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
          if ((columnIndex + 1) % 3 === 0) {
            return [1, 3]
          } else {
            return [0, 0]
          }
        } else if (rowIndex === this.tblLen - 2) {
          // 本组总涨粉：
          if (columnIndex === 0) {
            return [0, 0]
          } else if (columnIndex === 1) {
            return [1, 2]
          } else {
            return [1, 1]
          }
        } else {
          const fields = this.mergeFields
          if (column.property && fields.indexOf(column.property) > -1) {
            const _row = this.spanObj[column.property][rowIndex]
            const _col = _row > 0 ? 1 : 0
            return {
              rowspan: _row,
              colspan: _col
            }
          }
        }
      }
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      this.bonusRow = { nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金' }
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化
          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    //计算列的合计
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              // 累计考核的 目标值和完成值
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },

    async saveCell(postData) {
      await this.$api.savePerformanceTrendDeptData(postData)
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },

    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${ row.nickname },平台：${ row.platform_name },列：${ column.property }`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1 && ['nickname'].indexOf(column.property) > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        const left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop
        this.$nextTick(() => {
          this.$refs['refTrendPerformancePlatformsSelect'].init(top, left, row)
        })

      }
    },
    savedPlatformEvent() {
      //修改完红人考核平台
      this.$confirm('红人考核平台已调整，是否刷新数据?', '提示', {
        confirmButtonText: '刷新',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.getList()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      })
    },
    headerContextmenu(column, e) {
      e.preventDefault()//阻止元素发生默认的行为
      // console.log(column, e)
      if (column.property.indexOf('quotient_') > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        // this.dialogQuotientVisible = true

        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }

        // const top = e.clientY
        const top = e.layerY + 100

        this.$nextTick(() => {
          this.$refs['refPerformanceQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },


    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''
    },
    showSetting(query) {
      this.$notify.warning('设置考核')
      this.searchCondition = query

    },
    changeEditMode() {
      this.editMode = !this.editMode
      if (this.editMode && this.searchCondition.release_status !== 'N') {
        this.$notify.warning('切到编辑中的数据')
        this.searchCondition.release_status = 'N'
        this.getList()
      }
    },
    showBatch() {
      this.$emit('handleBatch')
      // let kolRange = []
      // //批量设置
      // if (this.activeName === 'trend') {
      //   kolRange = this.getCurrKolRange()
      //   this.$refs['batchSetPaTrend'].show(this.searchCondition, kolRange)
      // }
    },
    //获取红人集合（用于批量设置限定范围）
    getCurrKolRange() {
      let kolRage = []
      this.dataList.forEach((item) => {
        if (kolRage.indexOf(item.artist_id) === -1)
          kolRage.push(item.artist_id)
      })
      return kolRage
    },
    async exportExcel() {
      try {
        let fileName = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        if (this.activeName === 'trend') {
          let response = await this.$api.exportPaTrendRevenueQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else {
          this.$notify.success(this.activeName)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    showKolSetting() {
      this.$emit('handleSetting')
    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标？发布后其他人将能看到最新的数据, 是否继续?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPaDeptTrendDeptData(this.searchCondition)
      this.commitLoading = false
      if (info) {
        let msg = `${this.searchCondition.year}年${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】涨粉绩效目标数据发布`
        this.$notify.success(msg)
        this.editMode = false
        this.$emit('publishSaved')
      }
    },
    changedCheck() {
      setTimeout(() => {
        this.getList()
      }, 500)
    }

  }
}
</script>
<style>
.cell-check {
  background-color: #fefefe;
  /*text-decoration: line-through;*/
}

.cell-uncheck {
  background-color: rgba(223, 227, 234, 1);
  text-decoration: line-through;
}
</style>
<style scoped>
.group-name {
  color: var(--primary);
}

.default-edit-icon {
  float: right;
}

.tips li {
  /*font-weight: lighter;*/
  font-size: 8px;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
}

.table-view {
  max-height: 80vh;
  padding-bottom: 10px;
  padding-left: 5px;
}

.op-link {
  margin-left: 10px;
}

/**
核算样式
 */
.title-check {
  font-weight: 600;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
