<template>
  <div>
    <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners' @opened='onOpened' @close='onClose'
               :title='title' width='780px'>
      <el-form :model='searchCondition' inline size='mini'>
        <el-form-item>
          <artist-search ref='refArtistSearch' @handleSelect='handleSelectArtist' style='width: 400px'></artist-search>
        </el-form-item>
        <el-form-item>
          <el-button size='mini' type='primary' @click='handleQuery'>查询</el-button>
<!--          <el-button size='mini' type='text' @click='showMore=!showMore'-->
<!--                     :icon="!showMore?'el-icon-caret-bottom':'el-icon-caret-top'">更多-->
<!--          </el-button>-->
        </el-form-item>
      </el-form>
      <div style='margin-bottom: 8px'>
        <el-button :disabled='!multiple||loadingInit' :loading='loadingInit' type='warning'
                   @click='handleNeedCheckSelection("Y")'>批量考核({{
            selection.length
          }})项
        </el-button>
        <el-button :disabled='!multiple||loadingInit' :loading='loadingInit' type='danger'
                   @click='handleNeedCheckSelection("N")'>批量不考核({{
            selection.length
          }})项
        </el-button>
      </div>
      <div class='default-table' v-loading='loadingStatus'
           :element-loading-text='loadingText'
           element-loading-spinner='el-icon-loading'>
        <el-table
          :key='`${this.performanceType}_${searchCondition.year}_${searchCondition.quarter}_${searchCondition.group_id}`'
          :data='dataList'
          :highlight-current-row='true'
          @selection-change='handleSelectionChange'
          height='600px'>
          <el-table-column type='selection' width='55'></el-table-column>
          <el-table-column type='index' label='序号' width='55'></el-table-column>
          <el-table-column label='红人昵称' align='left' min-width='240' prop='nickname' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span>【{{ row.group_name || '' }}】</span>
              <span>{{ row.nickname || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='平台名称' align='center' width='100' prop='platform_name'
                           v-if='colsSet.indexOf("platform_name")>-1' />
          <el-table-column label='是否考核' align='center' width='100'>
            <template slot-scope='{row}'>
              <el-checkbox true-label='Y' false-label='N' v-model='row.need_check'
                           @change='handleChangeSingleCheck(row)' :disabled='loadingInit'></el-checkbox>
              <span v-if='loadingInit'>&nbsp;<i class='el-icon-loading'></i></span>
            </template>
          </el-table-column>
          <el-table-column label='操作' align='center'
                           v-if="performanceType==='revenue'" width='140'>
            <template slot-scope='{row}'>
              <el-button
                v-if='colsSet.indexOf("income_code")>-1'
                size='mini'
                type='text'
                icon='el-icon-edit'
                @click='updatePerformanceSetting(row,$event)'
              >收入分类（{{ calcRate(row) }}）
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--          平台收入分类设置-->
      <PaIncomeSetting :performance-type='`revenue`' ref='refPerformanceSetting' @savedEvent='getList'/>
      <!--  考核部门设置-->
      <PaDeptSetting ref='refDeptSetting'></PaDeptSetting>
    </el-dialog>
  </div>

</template>

<script>


import CompanyCascader from '@/components/dept/CompanyCascader'
import McnDeptSelect from '@/pages/pa/kol/McnDeptSelect'
import PaDeptSetting from '@/pages/pa/components/PaDeptSetting'
import ArtistSearch from '@/components/artist/ArtistSearch'
import PaIncomeSetting from '@/pages/pa/components/PaIncomeSetting'


export default {
  name: 'CheckSetting',
  components: { PaIncomeSetting, ArtistSearch, PaDeptSetting, McnDeptSelect, CompanyCascader },
  inheritAttrs: false,
  data() {
    return {
      loadingStatus: false,
      loadingInit: false,
      loadingText: '加载中',
      mergeFields: ['nickname'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      dataList: [],
      total: 0,
      pageData: { current_page: 1, page_size: 10 },
      orderBy: { artist_id: 'asc' },
      searchCondition: { year: null, quarter: null, artist_id: null, group_id: null, group_name: null },
      showQuery: false,
      showMore: false,
      dialogVisible: false,
      performanceType: 'trend',//revenue|trend
      platformsMap: {},//考核平台
      incomeTypes: {},//收入分类
      selection: [],
      multiple: false,
      settingMap: {
        'trend': { 'label': '涨粉', cols: ['platform_name'] },
        'revenue': { 'label': '营收', cols: ['platform_name', 'income_code'] },
        'gmv': { 'label': 'GMV', cols: [] }
      },
      colsSet: []

    }
  },
  computed: {
    title() {
      return `${this.settingMap[this.performanceType]?.label || ''}考核设置（${this.searchCondition.year}年第${this.searchCondition.quarter}季度 ${this.searchCondition.group_name}）`
    }
  },
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    show(searchCondition, performanceType) {
      this.performanceType = performanceType
      this.calcColsSet()
      //checkSetting
      this.searchCondition.year = searchCondition.year
      this.searchCondition.quarter = searchCondition.quarter
      this.searchCondition.group_id = searchCondition.group_id
      this.searchCondition.group_name = searchCondition.group_name
      this.dialogVisible = true

      this.showMore = false
      this.loadingInit = false
      this.$nextTick(() => {
        // this.loadMcnDeptList()
        this.getList()
      })

    },
    calcColsSet() {
      this.colsSet = this.settingMap[this.performanceType]?.cols || []
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      // 分页 +排序
      Object.assign(condition, { type: this.performanceType })

      return condition
    },
    async getList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      let data = await this.$api.getPaKolSetting(searchCondition)
      this.dataList = data.list || []
      this.incomeTypes = data.incomeTypes || {}
      this.platformsMap = data.platformsMap || {}
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      // console.log(fields)
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }

      //末尾查询一行合计：
      // if (rowIndex === this.dataList.length - 1) {
      //   // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
      //   if ((columnIndex + 1) % 3 === 0) {
      //     return [1, 3]
      //   } else {
      //     return [0, 0]
      //   }
      // } else if (rowIndex === this.dataList.length - 2) {
      //   // 本组总涨粉：
      //   if (columnIndex === 0) {
      //     return [0, 0]
      //   } else if (columnIndex === 1) {
      //     return [1, 2]
      //   } else {
      //     return [1, 1]
      //   }
      // } else {
      //   const fields = this.mergeFields
      //   if (column.property && fields.indexOf(column.property) > -1) {
      //     const _row = this.spanObj[column.property][rowIndex]
      //     const _col = _row > 0 ? 1 : 0
      //     return {
      //       rowspan: _row,
      //       colspan: _col
      //     }
      //   }
      // }

    },
    // async submitFormList() {
    //   if (this.performanceType === 'trend') {
    //     let content = this.dataList.map((item) => {
    //       return {
    //         artist_id: item.artist_id,
    //         nickname: item.nickname,
    //         platform_code: item.platform_code,
    //         platform_name: item.platform_name,
    //         // income_code: item.income_code,
    //         need_check: item.need_check
    //       }
    //     })
    //     let searchCondition = { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
    //     await this.$api.savePaKolTrendQuarterSetting(Object.assign(searchCondition, { content: content }))
    //     this.$emit('savedSetting')
    //   } else if (this.performanceType === 'revenue') {
    //     let content = this.dataList.map((item) => {
    //       return {
    //         artist_id: item.artist_id,
    //         nickname: item.nickname,
    //         platform_code: item.platform_code,
    //         platform_name: item.platform_name,
    //         income_code: item.income_code,
    //         income_type: item.income_type,
    //         need_check: item.need_check
    //       }
    //     })
    //     let searchCondition = { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
    //     await this.$api.savePaKolRevenueQuarterSetting(Object.assign(searchCondition, { content: content }))
    //     this.$emit('savedSetting')
    //   }
    //
    //   this.dialogVisible = false
    //
    // },

    async initQuarterSetting() {
      this.loadingInit = true
      if (this.performanceType === 'trend')
        await this.$api.initPaKolTrendSetting(this.searchCondition)

      if (this.performanceType === 'revenue')
        await this.$api.initPaKolRevenueSetting(this.searchCondition)

      this.loadingInit = false
      await this.getList()
    },
    onOpened() {

    },
    onClose() {
      this.dataList = []
      this.$emit('savedSetting')
    },
    selectDept(val) {
      this.searchCondition.dept_ids = val || []
    },
    handleSelectArtist(obj) {
      if (obj && obj.id)
        this.searchCondition.artist_id = obj.id
      else
        this.searchCondition.artist_id = null
    },
    reset() {
      this.pageData.current_page = 1
    },
    handleQuery() {
      //查询配置
      this.pageData.current_page = 1
      this.getList()
    },
    calcRate(currentRow) {
      let selectCount = 0
      let children = { ...currentRow.children }
      let total = 0
      if (children) {
        Object.keys(children).forEach((income_code) => {
          total++
          let item = children[income_code]
          // let currIncomeType = item['income_code']
          if (item.need_check === 'Y') {
            selectCount++
          }
        })
      }
      return `${selectCount}/${total}`
    },
    // eslint-disable-next-line no-unused-vars
    updatePerformanceSetting(row, e) {
      this.$nextTick(() => {
        this.$refs['refPerformanceSetting'].init(this.platformsMap, this.incomeTypes, row)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async handleChangeCheck(row) {
      this.loadingInit = true
      await this.$api.changePaKolSetting(row)

      this.$notify.success('考核设置修改成功，已发布数据需要重新发布后生效！！！')
      setTimeout(async () => {
        this.loadingInit = false
        await this.getList()
      }, 500)
    },
    handleSelectionChange(val) {
      this.selection = val
      this.multiple = this.selection.length > 0
    },

    handleNeedCheckSelection(need_check) {
      // console.log('handleNeedCheckSelection')
      this.loadingInit = true
      let rows = []
      this.selection.forEach((item) => {
        item.need_check = need_check
        item.type = this.performanceType
        const children = item.children
        if (children && Object.keys(children).length > 0) {
          Object.keys(children).forEach((income_code) => {
            let child = children[income_code]
            child.need_check = need_check
            child.type = this.performanceType
            rows.push({ ...child })
          })
        } else {
          rows.push(item)
        }
      })

      this.handleChangeCheck({ multiple: rows })
    },

    handleChangeSingleCheck(row) {
      row.type = this.performanceType

      let rows = []
      const children = row.children
      if (children && Object.keys(children).length > 0) {
        Object.keys(children).forEach((income_code) => {
          let child = children[income_code]
          child.need_check = row.need_check
          child.type = this.performanceType
          rows.push({ ...child })
        })
        this.handleChangeCheck({ multiple: rows })
      }else {

        this.handleChangeCheck({ single: row })
      }


    },
    showDeptSetting() {
      if (this.$refs['refDeptSetting'])
        this.$refs['refDeptSetting'].show(this.searchCondition)
    },
    loadDeptSetting(list) {

      if (!list || list.length === 0) {
        this.$confirm(`${this.searchCondition.year}年${this.searchCondition.quarter}季度考核部门未设置，是否设置`, '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.showDeptSetting()
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
      }
    }

  }
}

</script>

<style scoped>
.platform-tag {
  border: #e97ca1 1px solid;
  line-height: 20px;
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 5px;

}
</style>
