<template>
  <!--            说明-->
  <el-row>
    <el-col :span='12' style='text-align: left'>
      <Tips :data='tipsList' />
    </el-col>
    <el-col :span='12'>
      <ol>
        <li class='tips-title'>运营组月度绩效奖金包金额，算法为：
          <p class='tips-item'>1.1、上季度组平均月营收在0-30万（含30万整），本季度月奖金包核算为20000元；</p>
          <p class='tips-item'>1.2、上季度组平均月营收在30-50万（含50万整），本季度月奖金包核算为30000元；</p>
          <p class='tips-item'>1.3、上季度组平均月营收在50-100万（含100万整），本季度月奖金包核算为50000元；</p>
          <p class='tips-item'>1.4、上季度组平均月营收在100万以上，本季度月奖金包核算为60000元。</p>
          <p class='tips-item'>1.5、单月营收不足10万元，奖金包为0元。</p>
        </li>
        <li class='tips-title'>营收绩效奖金的计算比例，算法为：
          <p class='tips-item'>2.1、上季度组平均月营收在0-30万（含30万整），本季度营收奖金算法为超出部分20%；</p>
          <p class='tips-item'>2.2、上季度组平均月营收在30-50万（含50万整），本季度营收奖金算法为超出部分20%；</p>
          <p class='tips-item'>2.3、上季度组平均月营收在50-100万（含100万整），本季度营收奖金算法为超出部分15%；</p>
          <p class='tips-item'>2.4、上季度组平均月营收在100万以上，本季度营收奖金算法为超出部分10%。</p>
        </li>
      </ol>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'RevenueNote',
  data() {
    return {
      tipsList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1) 单个设置，右键“红人昵称/平台”，可进行配置；2) 整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）',
        '自营电商-完成值： 电商数据-店铺数据的月度总支付金额 * 0.03（系数），即：月总支付金额：10000，则完成值： 300（10000*0.03）。'
      ]
    }
  }
}
</script>

<style scoped>
.tips-title {
  /*font-weight: lighter;*/
  font-weight: 800;
  font-size: 14px;
  /*font-style: italic;*/
  /*color: #909399;*/
  margin-bottom: 5px;
  margin-top: 15px;
}

.tips-item {
  /*font-weight: lighter;*/
  font-size: 12px;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
  padding-left: 2em;
}
</style>
